import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import TimeCheckbox from '../forms/TimeCheckbox';
import Button from '../forms/Button';
import { setDaysView, selectSlotTime, selectTime } from '../../actions/appointmentActions';
import noData from '../../assests/images/issueIcon.svg';

const Days = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid rgb(231, 231, 231);
`;

const TitleDay = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.fontColor || '#424242'};
  opacity: .8;
  line-height: 1.2;
  margin: 0 0 10px 0;
  display: block;

  @media (max-width: 767px) {
    float: right;
    font-size: 1.2em;
    margin: 17px 10px 0;
  }
`;

const ResultMore = styled.div`
  width: 100%;
  height: 110px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;

  > button {
    background-color: transparent;
    color: ${(props) => props.colorSucses || 'rgb(31, 130, 224)'};
    height: auto;
    width: auto;
  }
`;

const TimeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    justify-content: space-between;
  }
`;

function Results({
  themeColor,
  openings,
  request,
  count,
  fontColor,
  selectedTime,
  dispatch,
  history,
}) {
  useEffect(() => {
    dispatch(selectTime(null));
  }, [dispatch]);

  function handleRadio(e, slotId, locationId) {
    const data = {
      time: e.target.value,
      slotId,
      locationId,
    };
    dispatch(selectSlotTime(data, history));
  }

  function sortTimeInArray(a, b) {
    return new Date(`1970/01/01 ${a.time}`) - new Date(`1970/01/01 ${b.time}`);
  }

  function countDay(openingsData, daysFormat, requestData) {
    const { dateFrom, dateTo } = requestData;
    const DateFrom = dateFrom
      ? moment
        .utc(new Date(dateFrom))
        .format('YYYY-MM-DD hh:mm a')
        .split(' ', 1)[0]
      : null;
    const DateTo = dateTo
      ? moment
        .utc(new Date(dateTo))
        .format('YYYY-MM-DD hh:mm a')
        .split(' ', 1)[0]
      : null;
    openingsData
      .map((opening) => ({
        elementStr: moment
          .utc(opening.start, 'YYYY-MM-DD hh:mm:ss')
          .format('YYYY-MM-DD hh:mm a')
          .split(' ', 3),
        slotId: opening.slotId,
        locationId: opening.locationId,
      }))
      .forEach((slotInfo) => {
        const [date, time, amPm] = slotInfo.elementStr;
        const equalDateIndex = daysFormat.findIndex((day) => day.date === date);
        if (equalDateIndex >= 0) {
          daysFormat[equalDateIndex].times.push({
            time: `${time} ${amPm}`,
            slotId: slotInfo.slotId,
          });
        } else if ((DateFrom || DateTo) && (date === DateFrom || date === DateTo)) {
          daysFormat.push({
            date,
            daysOfWeek: moment(date).day(),
            times: [{ time: `${time} ${amPm}`, slotId: slotInfo.slotId }],
            locationId: slotInfo.locationId,
          });
        } else {
          daysFormat.push({
            date,
            daysOfWeek: moment(date).day(),
            times: [{ time: `${time} ${amPm}`, slotId: slotInfo.slotId }],
            locationId: slotInfo.locationId,
          });
        }
      });
  }

  function seeAllResults(num) {
    dispatch(setDaysView(num + 3));
  }

  function renderTime(day, themeClr, fontClr) {
    return day.times.sort(sortTimeInArray).map((time) => (
      <TimeCheckbox
        fontColor={fontClr}
        themeColor={themeClr}
        onChange={(e) => handleRadio(e, time.slotId, day.locationId)}
        time={time.time.charAt(0) === '0' ? time.time.slice(1) : time.time}
        name={time.time}
        selectedTime={selectedTime}
        id={`${day.date} ${time.time}`}
        key={`${day.date} ${time.time}`}
      />
    ));
  }

  function renderDay(daysFmt, themeClr, fontClr) {
    const week = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    return daysFmt.map((day) => (
      <Days key={day.date}>
        <Row>
          <Row>
            <Col>
              <TitleDay fontColor={fontClr}>
                {week[moment(day.date).day() - 1]}
                ,
                {moment(day.date, 'YYYY-MM-DD').format('MMM')}
                {' '}
                {' '}
                {moment(day.date, 'YYYY-MM-DD').format('DD')}
              </TitleDay>
            </Col>
          </Row>
          <Row>
            <TimeContainer>
              {renderTime(day, themeClr, fontClr)}
            </TimeContainer>
          </Row>
        </Row>
      </Days>

    ));
  }

  const daysFormat = [];
  countDay(openings, daysFormat, request);
  const daysView = daysFormat.slice(0, count);
  const _ga = sessionStorage.getItem('_ga') || '';
  const noDays = {
    color: themeColor, padding: '0 20%', fontWeight: 'bold', fontSize: '1rem', marginTop: '20px',
  };
  if (!request.serviceId) {
    return <Redirect to={`/services${_ga}`} />;
  }
  if (!request.providerId) {
    return <Redirect to={`/providers${_ga}`} />;
  }
  function RenderContent() {
    if (openings && openings.length > 0) {
      return (
        <div>
          {renderDay(daysView, themeColor, fontColor)}
          {daysFormat.length > 3 && daysView.length === count && (
            <ResultMore colorSucses={themeColor}>
              <Button onClick={() => seeAllResults(count)}>
                See more appointments
              </Button>
            </ResultMore>
          )}
        </div>
      );
    } if (request.dateFrom && request.dateTo && sessionStorage.getItem('pressed')) {
      return (
        <div className="text-center">
          <img width="160px" className="m-auto" src={noData} alt="no data" />
          <p style={noDays}>
            No openings for the selected dates, please choose different days.
          </p>
        </div>
      );
    }
  }
  return (
    <RenderContent />
  );
}

Results.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  openings: PropTypes.array.isRequired,
  request: PropTypes.object,
  selectedTime: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
  openings: state.appointmentReducer.openings,
  request: state.appointmentReducer.request,
  count: state.appointmentReducer.count,
  selectedTime: state.appointmentReducer.selectedTime,
  fetchedTime: state.appointmentReducer.fetchedTime,
});

export default withRouter(connect(mapStateToProps)(Results));
