import { React, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormControl } from '../styles';
import { formatPhoneNumber } from './utils';

const FormGroupS = styled.div`
  text-align: left;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
`;

const Label = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${(props) => props.fontColor || '#424242'};
  margin-bottom: 10px;
`;
const InputS = styled.input`
  ${FormControl};
  border-color: ${(props) => props.borderColor};
`;

/* TODO do we need shadow? */
const Selected = styled.select`
  ${FormControl};
  /*box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, .6);*/
  &:focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const TextArea = styled.textarea`
  ${FormControl}
  height:80px;
`;

const ErrorText = styled.p`
  font-size: 14px;
  color: rgb(221, 0, 25);
  margin-bottom: 0;
  margin-top: 10px;
  font-family: Gotham-Medium;
`;
/*eslint-disable*/
function FormElem({
  labelContent,
  name,
  valid,
  type,
  maxLength,
  placeholder,
  trim,
  dropdownValues,
  defaultValue,
  error,
  errorMessage,
  focused,
  disabled,
  onChange,
  themeColor,
  fontColor,
  confirmed,
  value,
}) {
  const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
  const [fieldRequired, setFieldRequired] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const validatePhone = (data) => {
    if (data === '' || data === undefined) {
      return true;
    }
    return phoneUtil.isValidNumber(phoneUtil.parse(data, 'US'));
  }
  const validStyles = {
    borderColor: `${(fieldRequired || emailError || phoneError) ? 'red' : ''}`,
  }

  const handleChange = (event) => {
    let { value } = event.target;
    const name = event.target.name !== undefined ? event.target.name : 'no name';
    if (trim) value = value.trim();
    onChange(value, name, event.type);
  };

  const showError = () => {
    if (fieldRequired) {
      return <ErrorText>*required</ErrorText>
    }
    if (emailError) {
      return <ErrorText>Please enter email in valid format e.g(someone@domain.com) </ErrorText>
    }
    if (phoneError) {
      return <ErrorText>Not valid phone number, e.g. (XXX) XXX-XXXX</ErrorText>
    }
    if (valid.length > 0) {
      return <ErrorText>{valid[0].message}</ErrorText>;
    }
    if (error) {
      return <ErrorText>{errorMessage}</ErrorText>;
    }
    return null;
  };
  const onFocusOut = (e) => {
    if (valid.length > 0) {
      if (valid[0].name === 'noEmpty') {
        setFieldRequired(e.target.value ? false : true);
      }
    }
    if (name === 'email') {
      if (e.target.value) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        regex.test(e?.target?.value) ? setEmailError(false) : setEmailError(true)
      }
    }
    if (name === 'phone') {
      if (e.target.value && e.target.value !== '') {
        if (!validatePhone(formatPhoneNumber(e.target.value))) {
          setPhoneError(true);
        }
        else {
          setPhoneError(false);
        }
      }
    }
  }

  const renderInput = (color, fontColor) => [
    <input
      key={`${name}_0`}
      style={{ display: 'none' }}
      type={type}
      autoComplete="off"
      autoCorrect="off"
      value={value}
    />,
    <InputS
      fontColor={!confirmed && name === 'passwordConfirm' ? 'red' : fontColor}
      key={`${name}_1`}
      name={name}
      onBlur={onFocusOut}
      placeholder={placeholder}
      onChange={handleChange}
      maxLength={maxLength || 50}
      disabled={disabled}
      style={validStyles}
      type={type}
      defaultValue={defaultValue}
      autoComplete="off"
      autoCorrect="off"
      colorsucses={color}
      value={value}
    />,
  ];

  const renderSelect = (color, fontColor) => (
    <Selected
      onChange={handleChange}
      autoComplete="off"
      autoCorrect="off"
      colorsucses={color}
      name={name}
      fontColor={fontColor}
      defaultValue={defaultValue}
    >
      {dropdownValues.map((value) => {
        if (value === '') {
          return (
            <option
              value=""
              key={typeof value === 'string' ? value : value.value}
            >
              Please select...
            </option>
          );
        }
        return (
          <option
            value={typeof value === 'string' ? value : value.value}
            key={typeof value === 'string' ? value : value.value}
          >
            {typeof value === 'string' ? value : value.text}
          </option>
        );
      })}
    </Selected>
  );

  const renderTextarea = (color, fontColor) => (
    <TextArea
      key={`${name}_1`}
      placeholder={placeholder}
      onChange={handleChange}
      maxLength={200}
      disabled={disabled}
      colorsucses={color}
      name={name}
      fontColor={fontColor}
    />
  );

  const renderFieldByType = (type = 'Input', color, fontColor, name) => {
    if (type === 'Dropdown') {
      return renderSelect(color, fontColor);
    }
    if (type === 'Textarea') {
      return renderTextarea(color, fontColor);
    }
    if (name !== 'phone') {
      return renderInput(color, fontColor);
    }
    return renderInput(color, fontColor);
  };
  const renderLabel = (labelContent, fontColor) => (labelContent !== '' ? (
    <Label fontcolor={fontColor}>
      {' '}
      {labelContent}
    </Label>
  ) : (
    <></>
  ));
  return (
    <FormGroupS
      style={focused ? { background: 'transparent' } : null}
      className={valid.length > 0 || error ? 'Error' : null}
    >
      {renderLabel(labelContent, fontColor)}
      {renderFieldByType(type, themeColor, fontColor, name)}
      {showError()}
    </FormGroupS>
  );
}

FormElem.defaultProps = {
  error: false,
  errorMessage: 'This email is not valid',
};

FormElem.propTypes = {
  name: PropTypes.string,
  valid: PropTypes.array.isRequired,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  trim: PropTypes.bool,
  dropdownValues: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  focused: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  themeColor: PropTypes.string,
  fontColor: PropTypes.string,
  confirmed: PropTypes.bool,
  value: PropTypes.string,
  labelContent: PropTypes.string,
};

const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
});

export default connect(mapStateToProps)(FormElem);
