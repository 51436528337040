import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'react-datetime/css/react-datetime.css';
import { Row } from 'react-bootstrap';
import { FormControl } from '../styles';
import { Title } from '../../elements/SignUpForms';

// Updated styles to match other files
const DatetimeS = styled.div`
  margin-bottom: 22px;

  select {
    ${FormControl};
    width: 100%;
    background-color: white;
    border: 1px solid #AAAAAA;
    padding: 8px;
    font-size: 14px;

    &:focus {
      border-color: ${(props) => props.themeColor || '#007bff'};
      outline: none;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

const ErrorText = styled.p`
  font-size: 14px;
  color: rgb(221, 0, 25);
  margin-bottom: 0;
  margin-top: 10px;
  font-family: Gotham-Medium;
`;

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

class InputDate extends Component {
  constructor(props) {
    super(props);
    const newValue = props.value || props.defaultValue;
    const [month, day, year] = newValue.split('-');
    const monthName = monthNames[parseInt(month, 10) - 1] || '';
    this.state = {
      birthday: newValue || '',
      month: monthName || '',
      day: day ? parseInt(day, 10).toString() : '',
      year: year || '',
    };
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      const { year, month, day } = this.state;
      const monthNumber = (monthNames.indexOf(month) + 1).toString().padStart(2, '0');
      this.setState({ birthday: `${monthNumber}-${day}-${year}` }, () => {
        this.props.onDayChange(this.state.birthday);
      });
    });
  };

  getDaysInMonth = (month, year) => {
    const monthIndex = monthNames.indexOf(month);
    if (monthIndex === -1) return 31;
    return new Date(year || new Date().getFullYear(), monthIndex + 1, 0).getDate();
  };

  render() {
    const { themeColor, fontColor, errors } = this.props;
    const { month, year, day } = this.state;

    // Check if any of the date fields are empty
    const isDateIncomplete = month === '' || year === '' || day === '';

    let daysInMonth = this.getDaysInMonth(month, year);
    if (!daysInMonth > 0) daysInMonth = 31;
    const days = Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 100 }, (_, i) => `${currentYear - i}`);
    return (
      <DatetimeS themeColor={themeColor}>
        <div>
          <Row>
            <Title className="forms-label" fontColor={fontColor}>
              Birthday
            </Title>
          </Row>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: '2', paddingRight: '5px' }}>
              <select name="month" value={month} onChange={this.onChange}>
                <option value="">Month</option>
                {monthNames.map((m) => (
                  <option key={m} value={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ flex: '1', paddingRight: '5px' }}>
              <select name="day" value={day} onChange={this.onChange}>
                <option value="">Day</option>
                {days.map((d) => (
                  <option key={d} value={d}>
                    {d}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ flex: '1' }}>
              <select name="year" value={year} onChange={this.onChange}>
                <option value="">Year</option>
                {years.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {isDateIncomplete && <ErrorText>{errors}</ErrorText>}
      </DatetimeS>
    );
  }
}

InputDate.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errors: PropTypes.string,
  defaultValue: PropTypes.string,
  onDayChange: PropTypes.func.isRequired,
};

InputDate.defaultProps = {
  defaultValue: 'MM-DD-YYYY',
  errors: '',
};

export default InputDate;
